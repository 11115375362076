@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  background-color: #f2f2f2;
}
.btn {
  position: relative;
  width: 160px;
  height: 50px;
  line-height: 48px;
  background: #000;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 4px;
  text-decoration: none;
  -webkit-box-reflect: below 1px -webkit-linear-gradient(transparent, #0004);
}

.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    #fb0094,
    #00f,
    #0f0,
    #ff0,
    #f00,
    #fb0094,
    #00f,
    #0f0,
    #ff0,
    #f00
  );
  background-size: 400%;
  opacity: 0;
  filter: blur(20px);
  transition: 0.5s;
}
.btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    #fb0094,
    #00f,
    #0f0,
    #ff0,
    #f00,
    #fb0094,
    #00f,
    #0f0,
    #ff0,
    #f00
  );
  background-size: 400%;
  opacity: 0;
  transition: 0.5s;
}

.btn:hover::before,
.btn:hover::after {
  opacity: 1;
  animation: animate 20s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 300% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.btn span {
  position: absolute;
  display: block;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  text-align: center;
  background: #2c2c2c;
  color: rgb(108, 105, 105);
  transition: 0.5s;
  z-index: 1;
}

.btn:hover span {
  color: rgba(255, 255, 255, 1);
}

.btn span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: rgba(255, 255, 255, 0.1);
}
/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #21b5c9;
  border-radius: 10px;
  cursor: pointer;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #2d2d2d;
  border-radius: 10px;
}

/* Add this in your styles.css or a similar global CSS file */
.text-shadow-green {
  text-shadow: 22px 22px 42px rgba(0, 255, 0, 0.5);
}

.s {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
